'use client'

import { useTranslation } from '@/app/i18n/client'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Fragment } from 'react'
import { HSeparator } from './Separator'

export const FAQ = () => {
  const { t } = useTranslation('landing')

  return (
    <Accordion data-testid='faq-accordion' type='single' collapsible>
      {([1, 2, 3, 4, 5, 6] as const).map(n => (
        <Fragment key={n}>
          <AccordionItem className='border-none' value={`faq-${n}`}>
            <AccordionTrigger data-testid='accordion-title' chevronClassName='text-brand-main w-6 h-6'>
              <h4 className='text-left font-semibold text-xl md:text-2xl'>{t(`section5.faq.${n}.question`)}</h4>
            </AccordionTrigger>
            <AccordionContent data-testid='accordion-content' className='flex flex-col gap-1'>
              <p className='text-base text-gray-400'>{t(`section5.faq.${n}.answer`)}</p>
            </AccordionContent>
          </AccordionItem>
          <HSeparator className='my-6' />
        </Fragment>
      ))}
    </Accordion>
  )
}
